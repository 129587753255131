<template>
    <div class="content">
        <!-- 左边 -->
        <div class="left-box">

            <!-- 通知 -->
            <div class="notice" v-if="noticeBool">
                <div class="notice-content">
                    <img src="~@/assets/index/noticeIcon.png" alt="">
                    <p>{{ newsDate.tipTitle }}</p>
                    <span @click.stop="navTo(`/newsDetails?id=${newsDate.id}`)">查看详情</span>
                </div>
                <i class="el-icon-close" @click="noticeCk"></i>
            </div>
            <!-- 数据总览 -->
            <div class="data-total">
                <div class="data-header">
                    <div class="data-title">
                        <div class="cot"></div>
                        <h3>数据总览</h3>
                    </div>
                    <ul>
                        <li :class="totalNumber === 1 ? 'headerliCk' : ''" @click="totalCk(1)">今日</li>
                        <li :class="totalNumber === 2 ? 'headerliCk' : ''" @click="totalCk(2)">昨日</li>
                        <li :class="totalNumber === 3 ? 'headerliCk' : ''" @click="totalCk(3)">一周</li>
                        <li :class="totalNumber === 4 ? 'headerliCk' : ''" @click="totalCk(4)">一月</li>
                        <li :class="totalNumber === 5 ? 'headerliCk' : ''" @click="totalCk(5)">全部</li>
                    </ul>
                </div>
                <div class="total-footer">
                    <div class="total-item">
                        <div class="total-text">
                            <img src="~@/assets/index/money.png" alt="">
                            <p>累计收益（元）</p>
                        </div>
                        <p class="total-money">¥{{ totalData.momey || 0 }}</p>
                    </div>
                    <div class="total-item">
                        <div class="total-text">
                            <img src="~@/assets/index/music.png" alt="">
                            <p>签约合同（个）</p>
                        </div>
                        <p class="total-money">{{ totalData.count || 0 }}</p>
                    </div>
                    <div class="total-item">
                        <div class="total-text">
                            <img src="~@/assets/index/money.png" alt="">
                            <p>结算待确定（个）</p>
                        </div>
                        <p class="total-money">{{ totalData.conMon || 0 }}</p>
                    </div>
                </div>
            </div>
            <!-- 收益数据profit -->
            <div class="data-total">
                <div class="data-header">
                    <div class="data-title">
                        <div class="cot"></div>
                        <h3>收益数据</h3>
                    </div>
                </div>
                <!-- 柱状图 -->
                <div id="profit"></div>
            </div>
            <!-- 平台数据：platform -->
            <div class="data-total">
                <div class="data-header">
                    <div class="data-title">
                        <div class="cot"></div>
                        <h3>平台数据</h3>
                    </div>
                </div>
                <Total :totalData="platformData" :total="platformDataTotal"></Total>
                <!-- <div class="platform" id="pieChart">


                </div> -->
            </div>
        </div>
        <!-- 右边 -->
        <div class="right-box">

            <div class="right-box-cont">
                <div style="margin-top: 1em;">
                    <User ></User>
                </div>
                <!-- 平台数据：platform -->
                <div class="data-total">
                    <div class="data-header">
                        <div class="data-title">
                            <div class="cot"></div>
                            <h3>平台数据</h3>
                        </div>
                    </div>
                    <div class="platformTotal">
                        <div class="total-footer">
                            <div class="total-item platformTotal-item" v-for="(item, index) in rightplatformData"
                                :key="item.faUserAuthT.id">
                                <h5>{{ item.faUserAuthT.name }}</h5>
                                <div class="platformTota-item">
                                    <div class="platformTotal-money">
                                        <p>{{ item.allMoney || 0 }}</p>
                                        <span>总收益</span>
                                    </div>
                                    <div class="platformTotal-number">
                                        <p>{{ item.count || 0 }}</p>
                                        <span>合同数</span>
                                    </div>
                                    <div class="platformTotal-money">
                                        <p>{{ item.money || 0 }}</p>
                                        <span>近期收入</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <!-- 活动专区 -->
                <div class="data-total">
                    <div>
                        <div class="data-title">
                            <div class="cot"></div>
                            <h3>活动专区</h3>
                        </div>
                        <!-- 轮播图 -->
                        <div class="banner">
                            <el-carousel trigger="click">
                                <el-carousel-item class="banner-item" v-for="item in banner" :key="item">
                                    <img :src="item" alt="">
                                </el-carousel-item>
                            </el-carousel>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</template>
<script>
// let a = document.getElementById('profit')
// console.log(a);
import * as echarts from 'echarts'
import User from "./../../components/user.vue"
import Total from './../../components/total.vue'
export default {
    components: {
        User,
        Total
    },
    data() {
        return {
            noticeBool: true,//通知展示
            newsDate: [],//通知信息
            totalNumber: 1,//数据总揽
            totalData: [],//数据总揽数据
            platformData: [],//平台数据
            platformDataTotal:'',//平台数据的总数量
            rightplatformData: [],//右侧平台数据
            banner: [],
            PrpfitX: [],//x轴名称
            PrpfitData: [],//收益数据
        }
    },
    created() {
        let token = localStorage.getItem('token')
        if(!token) {
            this.navTo('/login')
        }
        this.PostfindNowTip();//查看最新消息
        this.PostDataOverview();//数据总揽
        this.PostDataplatform();//平台数据
        this.Postconfig();//信息活动
        this.PostphotoUser();//数据可视化
    },
    mounted() {
        // this.DOMpieChart();//添加饼状图

        // this.patform();//饼图

    },
    methods: {
        // 最新的消息
        PostfindNowTip() {
            let userID = localStorage.getItem('userID')
            this.$api.findNowTip({
                userId: userID
            }).then(res => {
                this.newsDate = res.data[0]
            })
        },
        // 数据总揽
        PostDataOverview() {
            let userID = localStorage.getItem('userID')
            this.$api.DataOverview({
                userId: userID,
                status: this.totalNumber
            }).then(res => {
                this.totalData = res
            })
        },
        // 平台数据：右侧
        PostDataplatform() {
            let userID = localStorage.getItem('userID')
            this.$api.Dataplatform({
                userId: userID
            }).then(res => {
                this.rightplatformData = res.data
            })
        },
        // 活动信息
        Postconfig() {

            this.$api.config().then(res => {

                this.banner = res.actpics

            })
        },
        // 数据可视化
        PostphotoUser() {
            let that = this
            let userID = localStorage.getItem('userID')
            this.$api.photoUser({
                userId: userID
            }).then(res => {
                res.month.forEach((item, index, arr) => {
                    if (index < 12) {
                        if (item.months) {
                            that.PrpfitX.push(item.months);
                        } else {
                            that.PrpfitX.push(`${index + 1}`);
                        }
                    }
                });
                res.month.forEach((item, index, arr) => {
                    if (index < 12) {
                        if (item.allM) {
                            that.PrpfitData.push(item.allM)
                        } else {
                            that.PrpfitData.push('0')
                        }
                    }
                });
                this.profitFn();//柱状图

                this.platformData = res.data
                this.platformDataTotal = res.allshouyi
            })
        },

        //柱状图
        profitFn() {
            // console.log(this.PrpfitData,'this.PrpfitData');
            let chart = document.getElementById('profit');
            let myChart = echarts.init(chart);
            let option = {
                grid: {
                    left: 20,
                    buttom: 0,
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: this.PrpfitX,
                },
                yAxis: {
                    type: 'value',
                    show: true,
                    "splitLine": {
                        "show": false
                    },
                    "axisTick": {       //y轴刻度线
                        "show": true
                    },
                    "axisLine": {
                        "show": true
                    }
                },
                series: [{
                    label: {
                        show: true,
                        position: 'top'
                    },
                    barCategoryGap: '60%',
                    data: this.PrpfitData,
                    type: 'bar',
                    showVackground: true,
                    backgroundStyle: {
                        color: 'rgba(180,180,180,0.2)'
                    }
                }],
            };
            option && myChart.setOption(option)
        },

        // 点击关闭通知模态框
        noticeCk() {
            this.noticeBool = false
        },
        // 总览数据切换
        totalCk(number) {
            this.totalNumber = number
            this.PostDataOverview();//数据总揽
        }
    }
}
</script>

<style scoped>
.content {
    display: flex;
    justify-content: space-between;
}

.left-box {
    background-color: #fff;
    width: 60%;
    padding: 4%;
}

.notice {
    display: flex;
    align-content: center;
    justify-content: space-between;
    padding: 1em;
    border: solid 1px #2e51ef;
    background-color: rgba(59, 78, 244, .1);
}

.notice-content {
    height: 1em;
    display: flex;
    align-items: center;
}

.notice-content img {
    width: 1em;
    height: 1em;
    margin-right: 1em;
}

.notice-content p {
    color: #111577;
    margin-right: 1em;
}

.notice-content span {
    text-decoration: underline;
    color: #2e51ef;
}

.data-total {
    width: 100%;
    margin-top: 3em;
}

.data-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}



.data-header ul {
    display: flex;
    border: 1px solid #eee;
}

.data-header ul li {
    /* background-color: #2e51ef; */
    padding: 1em;
}

.headerliCk {
    background-color: #2e51ef;
    color: #fff;
}

.total-footer {
    margin-top: 2em;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.total-item {
    margin-bottom: 2em;
    /* margin-right: 2em; */
    width: 20em;
    min-width: 13em;
    padding: 1em;
    border-radius: 0.4em;
    border: 1px solid #eee;
    background-color: #f7f8fa;
}

.total-item:last-child {
    margin-bottom: 0;
}

.total-text {
    display: flex;
    align-items: center;
}

.total-text img {
    width: 1em;
    height: 1em;
    margin-right: 1em;
}

.total-text p {
    color: #333;
}

.total-money {
    margin-top: 1em;
    font-size: 2em;
    color: #333;
}

/* 柱状图 */
#profit {
    width: 100%;
    height: 22em;
}

/* 饼图 */


.platform {
    margin-top: 2em;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}







.right-box {
    background-color: #fff;
    border-left: 2px solid #eee;
    width: 40%;
    min-height: 100vh;
}

.right-box-cont {
    width: 90%;
    margin: 0 auto;
}

.platformTotal-item {
    width: 100%;
    min-width: 16em;
}

.platformTotal h5 {
    font-size: 1em;
    color: #333333;
    margin-bottom: 1em;
}

.platformTota-item {

    display: flex;
    ;
    justify-content: space-between;
    align-items: center;
}

.platformTota-item div {
    color: #ff4e4e;
    text-align: center;
}

.platformTotal-number p {
    color: #2e51ef;
}

.platformTota-item div span {
    margin-top: 1em;
    display: block;
    color: #666666;
}

/* 轮播图 */
.banner {
    margin-top: 1em;
    width: 100%;
    min-width: 20em;
}

.banner-item {
    width: 100%;
    min-width: 20em;
    object-fit: cover;
}
.banner-item img{
    width: 100%;
    height: 100%;
}
</style>
