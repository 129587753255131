<template>
    <div>
        <div id="pieChart">
        </div>
    </div>
</template>
<script>
import * as echarts from 'echarts'
export default{
    props:['totalData','total'],
    data(){
        return{
            toData:[],
            number:'',//总数
            color:['#12be73','#f9161e','#ffc000','#1f7eff','#fb8363','#d751cf','#519dd7','#49c07b']
        }
    },
    watch:{
        total(news){
            this.number = news
        },
        totalData(news,olds){
            
            this.toData = news
            news.forEach((item,index) => {
                // 百分比

                // console.log(item.faUserAuthT.id,item.faUserAuthT.name,item.money,item.allMoney);
                this.DOMpieChart(item.faUserAuthT.id,item.faUserAuthT.name,item.money,item.allMoney)
            });
            
        }
    },
    mounted(){
        // console.log(this.toData);
        // this.DOMpieChart()
    },
    methods:{

         // 添加饼状图
         DOMpieChart(id,name,money,allMoney) {
            let pieChart = document.getElementById('pieChart')
            let pie = document.createElement('div')
            pie.setAttribute('class', 'platform-item')
            pie.innerHTML =
                `<div id='patformItem${id}' style='width: 5em;height:5em;display: flex;'>`
                + "</div>"
                + "<div class='platform-item-text'>"
                + `<h5>${name}</h5>`
                + `<p>累计收益${allMoney}</p>`
                + "</div>"
            // console.log(pieChart,'DOM');
            pieChart.appendChild(pie)
            this.patform('patformItem'+id,allMoney)
        },
        // 百分比运算
        percentage(data,money){
            let number = Number(data);//总数
            let allMoney = Number(money);//数量
            let percen = allMoney / number * 100 
            // console.log(number,allMoney,percen,'看这里');
            return percen+'%'
        },
        // 平台数据:饼状图
        patform(DOMID,allMoney) {
            // console.log(this.number,'s');
            let text = this.percentage(this.number,allMoney)
            let Dom = document.getElementById(DOMID);
            let index = 0;
            index+=1
            if(index=7){
                index = 0
            }
            console.log(Dom,'Dom');
            // return
            let myDom = echarts.init(Dom);
            let option = {
                tooltip: {
                    trigger: 'item'
                },

                color:['#eee',this.color[index]],
                legend: {
                    top: '5%',
                    left: 'center'
                },
                series: [
                    {
                        type: 'pie',
                        radius: ['60%', '80%'],
                        label: {
                            show: false,//去除指视线
                        },
                        // 数据
                        data: [
                             { value: this.number - allMoney},{value:allMoney}
                        ]
                    }
                ],
                graphic: {
                    // 饼图字体
                    elements: [{
                        type: 'text',
                        left: 'center',
                        top: '45%',
                        style: {
                            text: text,
                            fontSize: 10,
                            textAlign: 'content',
                            width: 30,
                            height: 25,
                            fill: '#666666'
                        }
                    }]
                }
            };
            option && myDom.setOption(option)
        },
    }

}
</script>
<style>
#pieChart{
    display: flex;
    flex-wrap: wrap;
    margin-top: 2em;
}
.platform-item {

    width: 12em;
    min-width: 12em;
    margin-right: 2em;
    display: flex;

    align-items: center;
}

.platform-item-text h5 {
    font-size: 1em;
    /* color: #333333; */
}

.platform-item-text p {
    margin-top: 0.4em;
    color: #666666;
}
</style>